/* Fix for double scrollbar issue */
html, body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  /* Main container styles */
  .ibm-page-wrapper {
    overflow-x: hidden;
    width: 100%;
    padding: 0;
    margin: 0;
    height: auto;
    min-height: 100vh;
    z-index: auto !important;
  }
  
  /* Fix for the project section container */
  .projectDelta-section1 {
    overflow: visible !important;
    width: 100%;
    padding-bottom: 3rem;
    position: relative !important;
    z-index: 2 !important;
  }
  
  /* Preserve the background styling */
  .landing-container.project.delta {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow-x: hidden;
    pointer-events: auto !important;
    overflow: visible !important;
    
  }
  
  /* Updated container styles to maintain background but fix scrolling */
  .landing-container {
    height: auto;
    min-height: 100vh;
    position: relative;

  }
  
  .page-content {
    height: auto;
    min-height: unset;
    position: relative;
  }
  
  /* Fix for the card wrapper */
  .delta-card-wrapper {
    position: relative !important;
    z-index: 10 !important;
    overflow: visible !important;
    pointer-events: auto !important;
  }
  
  /* Fix the card itself */
  .metallic-card-indiv {
    position: relative !important;
    z-index: 10 !important;
    transform-style: preserve-3d !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease !important;
    pointer-events: auto !important;
  }
  
  /* Make sure the card itself can receive mouse events */
  .metallic-card-indiv * {
    pointer-events: auto !important;
  }
  
  /* Make sure containers don't have competing scroll behavior */
  .container,
  .MuiContainer-root {
    overflow: visible !important;
    pointer-events: auto !important;
  }
  
  /* Smooth scrolling for the entire page */
  html {
    scroll-behavior: smooth;
  }
  
  /* Make sure images and videos don't cause overflow */
  img, video {
    max-width: 100%;
    height: auto;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .project-container {
      padding: 0 10px;
    }
    
    .delta-card-wrapper {
      padding-top: 2rem;
    }
  }
  
  /* Animation smoothness improvements */
  .animate__animated {
    will-change: transform, opacity;
  }
  
  /* Ensure MUI ThemeProvider doesn't block pointer events */
  .MuiThemeProvider-root * {
    pointer-events: auto !important;
  }

.add-space-process {
    margin-top: 3rem;
}
/* Force pointer events on all elements in the button chain */
.chi-paper-button-wrapper {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    position: relative;
    z-index: 9999;
    pointer-events: all !important;
  }
  
  .chi-paper-button-link {
    pointer-events: all !important;
    position: relative;
    z-index: 9999;
    display: inline-block;
  }
  
  .chi-paper-button {
    background-color: white;
    color: black;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    border-radius: 35px;
    position: relative;
    z-index: 9999;
    pointer-events: all !important;
  }
  
  .chi-paper-button:hover {
    background-color: #f0f0f0;
  }