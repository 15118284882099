@font-face {
    font-family: 'OpenSauceSans';
    src: url('../public/fonts/OpenSauceSans-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSauceSansLight';
    src: url('../public/fonts/OpenSauceSans-Light.ttf') format('truetype');

}

.footer-container {
    background-color: #131313;
    color: #ffffff;
    padding: 20px 0;
    width: 100%;
    position: relative;
    z-index: 10;

  
    /* border: 1px solid rgba(59, 59, 50, 0.2); */
    color: var(--text-color-light);
    box-shadow: var(--box-shadow-inset);
    -webkit-backdrop-filter: var(--filter-blur);
    backdrop-filter: var(--filter-blur);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);

}

.footer-content-wrapper {

    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.footer-tagline {
    font-family: 'OpenSauceSansLight', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif !important;

    font-size: 0.9rem !important;
    color: #e8e8e8;
    letter-spacing: 0.2px;
}

.footer-collaborate {
    font-family: 'OpenSauceSans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif !important;

    font-size: 0.9rem !important;
    color: #e8e8e8;
    letter-spacing: 0.2px;
}

.divider {
    margin: 0 4px;
    opacity: 0.7;
}

.social-icons {
    display: flex;
    align-items: center;
}

.social-icon {
    color: #ffffff !important;
    margin: 0 4px !important;
    padding: 6px !important;
    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;
    background-color: #393939 !important;
    transition: background-color 0.3s ease;
}

.social-icon svg {
    font-size: 0.9rem;
}

.social-icon:hover {
    background-color: #818181 !important;
}

@media (max-width: 600px) {
    .centered-footer-content {
        flex-direction: column;
        gap: 10px;
    }

    .divider {
        display: none;
    }
}