@font-face {
    font-family: 'OpenSauceSans';
    src: url('../public/fonts/OpenSauceSans-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSauceSansLight';
    src: url('../public/fonts/OpenSauceSans-Light.ttf') format('truetype');

}

@font-face {
    font-family: 'OpenSauceSansMedium';
    src: url('../public/fonts/OpenSauceSans-Medium.ttf') format('truetype');

}

@font-face {
    font-family: 'OpenSauceSansBold';
    src: url('../public/fonts/OpenSauceSans-Bold.ttf') format('truetype');

}

@font-face {
    font-family: 'OpenSauceSansLightItalic';
    src: url('../public/fonts/OpenSauceSans-LightItalic.ttf') format('truetype');

}

.education-company-container {
    display: flex;
    align-items: center;
    gap: 15px;
    height: 40px;
    /* margin-top: 10px; */
    margin-bottom: 20px;
    margin-left: 4rem;
  }
  
  .education-section {
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
  }
  
  .ms-text {
    font-family: 'OpenSauceSansLight', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif !important;

    color: white;
    font-size: 16px;
    font-weight: 500;
  }
  
  .gt-text {
    font-family: 'OpenSauceSansBold', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif !important;
    color: #D7B750; /* Georgia Tech gold */
    font-size: 16px;
    font-weight: 500;
  }
  
  .divider {
    color: white;
    font-size: 16px;
    margin: 0px 0px;
  }
  
  .experience-container {
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
  }
  
  .experience-text {
    font-family: 'OpenSauceSansLight', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif !important;
    font-size: 16px;
    color: white;
    font-weight: 500;
  }
  
  .company-slider {
    position: relative;
    height: 40px;
    width: 120px; /* Adjust based on your longest company name */
    display: inline-block;
    overflow: hidden;
  }
  
  .company-name {
    font-family: 'OpenSauceSansBold', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif !important;
    position: absolute;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
    top: 9px;
    left: 0;
  }
  
  /* Animation for the outgoing company name */
  .slide-out {
    animation: slideOut 0.5s forwards;
  }
  
  /* Animation for the incoming company name */
  .slide-in {
    animation: slideIn 0.5s forwards;
  }
  
  @keyframes slideOut {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(40px);
      opacity: 0;
    }
  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(-40px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }