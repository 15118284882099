/* @import url('https://fonts.googleapis.com/css?family=Josefin+Sans'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

@font-face {
  font-family: 'OpenSauceSans';
  src: url('../public/fonts/OpenSauceSans-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OpenSauceSansLight';
  src: url('../public/fonts/OpenSauceSans-Light.ttf') format('truetype');

}

@font-face {
  font-family: 'OpenSauceSansMedium';
  src: url('../public/fonts/OpenSauceSans-Medium.ttf') format('truetype');

}

@font-face {
  font-family: 'OpenSauceSansBold';
  src: url('../public/fonts/OpenSauceSans-Bold.ttf') format('truetype');

}

@font-face {
  font-family: 'OpenSauceSansLightItalic';
  src: url('../public/fonts/OpenSauceSans-LightItalic.ttf') format('truetype');

}


:root {
  --bg-color-play: hsl(228, 76%, 25%);
  --bg-color-play: hsl(228, 76%, 25%);
  --bg-color-work: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
  --bg-color-about: #8b0000;
  --text-color-play: rgb(221, 221, 221);
  --text-color-work: rgb(255, 255, 255);
  --tile-bg-color-play: #3b3bd1;
  /* Dark blue */
  --tile-bg-color-work: #fcfcfc;
  --text-color-light: rgb(156, 160, 171);
  --box-shadow-inset: inset 0 22px 56px -36px rgba(255, 255, 255, 0.069),
    inset 0 4px 5px -4px rgba(255, 255, 255, 0.12),
    inset 0 -31px 34px -32px rgba(96, 68, 144, 0.218),
    inset 0 39px 50px -34px rgba(202, 172, 255, .3),
    inset 0 2px 9px rgba(155, 146, 210, 0.172),
    inset 0 1px 10px rgba(227, 222, 255, 0.129);

  --box-shadow-inset-button: inset 0 2px 7px -9px rgba(255, 255, 255, .5),
    inset 0 1px 2px -1px rgba(255, 255, 255, 1),
    inset 0 -20px 16px -32px rgba(96, 68, 144, .3),
    inset 0 28px 25px -24px rgba(202, 172, 255, .3),
    inset 0 1px 4px rgba(154, 146, 210, .3),
    inset 0 1px 10px rgba(227, 222, 255, .2);

  --box-shadow-inset-button: inset 0 2px 7px -9px rgba(255, 255, 255, .5),
    inset 0 1px 2px -1px rgba(255, 255, 255, 1),
    inset 0 -20px 16px -32px rgba(96, 68, 144, .3),
    inset 0 28px 25px -24px rgba(202, 172, 255, .3),
    inset 0 1px 4px rgba(154, 146, 210, .3),
    inset 0 1px 10px rgba(227, 222, 255, .2);

  --box-shadow-inset-button-hover: inset 0 4px 7px -4px rgba(255, 255, 255, .5),
    inset 0 1px 2px -1px rgba(255, 255, 255, 1),
    inset 0 -20px 16px -32px rgba(96, 68, 144, .3),
    inset 0 28px 25px -20px rgba(202, 172, 255, .3),
    inset 0 1px 4px rgba(154, 146, 210, .3),
    inset 0 1px 16px rgba(227, 222, 255, .2);


  --filter-blur: blur(100px);

  --background: #4285f4;
  --icon-color: #344955;
  --width: 60px;
  --height: 60px;
  --border-radius: 100%;

}

html, body {
  overflow-x: hidden;
  max-width: 100vw;
  font-family: 'Inter', sans-serif;
}




.body {
  
  font-size: '16px';
}

.landing-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.landing-container.play,
.landing-container.work {
  position: relative;
}

/* Common background properties */
.landing-container.play::before,
.landing-container.work::before,
.landing-container.project::before,
.landing-container.about::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  background-image: url('bg.jpeg');
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  mask-image: 
    linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%),
    linear-gradient(90deg, transparent, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 80%, transparent);
  -webkit-mask-image: 
    linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%),
    linear-gradient(90deg, transparent, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 80%, transparent);
  -webkit-mask-composite: source-in;
  mask-composite: intersect;
  z-index: 1;
}


/* Play page specific background */
.landing-container.play::before {
  opacity: 0.15;
  height: 30%;
}

/* Work page specific background */
.landing-container.work::before {
  opacity: 0.55 !important;
  height: 30%;
}

/* Project page specific styles */
.landing-container.project {
  position: relative;
  min-height: 100vh;
  height: auto;
  /* background: linear-gradient(180deg, rgb(3, 0, 0) 0%, rgb(122, 35, 35) 53%, rgb(18, 15, 38) 100%); */
  color: var(--text-color-light);
  overflow: visible;
}

.landing-container.project.micro {
  background: linear-gradient(180deg, #0C090E 100%, #0C090E 100%, #0C090E 100%);
}

.landing-container.project.delta {
  background: linear-gradient(180deg, #0C090E 100%, #0C090E 100%, #0C090E 100%);
}

.landing-container.project.airport {
  background: linear-gradient(180deg, #0C090E 100%, #0C090E 100%, #0C090E 100%);
}

.landing-container.project::before {
  opacity: 0.4;
  height: 60%;
  position: fixed;
}

/* Project content wrapper */
.projectDelta-section1 {
  position: relative;
  z-index: 2;
  justify-content: center;
  color: #fffefe !important;
  width: 100%;
  padding-bottom: 1.5rem;
}

.projectAirport-section1 {
  position: relative;
  z-index: 2;
  justify-content: center;
  color: #fffefe !important;
  width: 100%;
  padding-bottom: 3rem;
}

.project1-section1 {
  padding-bottom: 3rem;
  
}

/* About page specific styles */
.landing-container.about {
  position: relative;
  min-height: 100vh;
  height: auto;
  background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(15, 12, 41, 1) 53%, rgba(46, 39, 103, 1) 100%);
  color: var(--text-color-light);
}

.landing-container.about::before {
  opacity: 0.2;
  height: 40%;
}




.glow-left-top,
.glow-left-bottom,
.glow-right-top,
.glow-right-bottom {
  position: absolute;
  width: 500px;
  height: 500px;
  pointer-events: none;
  z-index: 2;
  filter: blur(90px);
  animation: glowPulse 4s ease-in-out infinite;
}

/* Left spots */
.glow-left-top {
  top: -220px;
  left: -70px;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.15) 20%,
    transparent 70%
  );
}

.glow-left-bottom {
  width: 160px;
  height: 50px;
  top: 60px;
  left: 620px;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.656) 100%,
    transparent 100%
  );
  animation-delay: 1s;
}

/* Right spots */
.glow-right-top {
  top: -250px;
  right: 0px;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.09) 30%,
    transparent 70%
  );
  animation-delay: 2s;
}

.glow-right-bottom {
  top: 100px;
  right: 10px;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.144) 0%,
    rgba(255, 255, 255, 0.098) 3%,
    transparent 70%
  );
  animation-delay: 20s;
}

@keyframes glowPulse {
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.7;
  }
}

.landing-container.play::before {
  opacity: 0.3;
}

.landing-container.work::before {
  opacity: 0.6;
}

.landing-container.play {
  /* background: #005C97; */
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to right, #363795, #005C97); */
  /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to right, #363795, #005C97); */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: rgb(2,0,36);
  background: linear-gradient(180deg, rgb(0, 1, 11) 0%, rgb(0, 8, 12) 53%, rgb(14, 22, 35) 100%); 
  color: var(--text-color-light);


}

.landing-container.work {
  
  background: #18181B;
  background: rgb(1, 0, 15);
  background: linear-gradient(180deg, rgb(0, 0, 3) 0%, rgb(4, 3, 13) 53%, rgb(18, 15, 38) 100%);
  color: var(--text-color-light);

  /* background: var(--bg-color-work);
  color: var(--text-color-work); */
}

.landing-container.about {

  color: var(--text-color-light);
  background: rgb(2, 0, 36);
  background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(15, 12, 41, 1) 53%, rgba(46, 39, 103, 1) 100%);
  color: var(--text-color-light);
  min-height: 1100px;
  /* Ensures the container is at least as tall as the viewport */
}

css-1oqqzyl-MuiContainer-root {
  max-width: none;

}

.landing-container.project .MuiTypography-root {
  /* Remove or comment out the background color setting */
  font-size: 18px;
  /* min-height: 100vh; */
  /* transition: background 0.5s ease; */
}

.landing-container.project {
  pointer-events: none; /* Allow mouse tracking to pass through */
}

.landing-container.project .page-content {
  pointer-events: auto; /* Re-enable clicks for actual content */
  position: relative;
  z-index: 5;
}





.menu {
  cursor: pointer;
  color: #fcfcfc;
  border-radius: 50%;
  /* Make it circular */
  display: flex;
  justify-content: center;
  /* Center the icon horizontally */
  align-items: center;
  /* Center the icon vertically */
  width: 60px !important;
  /* Adjust width as needed */
  height: 60px !important;
  /* Adjust height as needed */
  box-shadow: var(--box-shadow-inset);
  backdrop-filter: var(--filter-blur);
  backdrop-filter: blur(10px);
}

.menu-top {
  position: relative;
  z-index: 10;
}




.wrapper {
  z-index: 3;
  width: var(--width);
  height: var(--height);
  position: relative;
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 6rem; */
}

.wrapper .fab {
  box-shadow: var(--box-shadow-inset);
  backdrop-filter: var(--filter-blur);
  backdrop-filter: blur(10px);
  width: var(--width);
  height: var(--height);
  position: relative;
  z-index: 3;
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fab-animation-reverse 0.4s ease-out forwards;
}

.wrapper .fab::before,
.wrapper .fab::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 4px;
  background: #fff;
}

.wrapper .fab::before {
  width: 4px;
  height: 18px;
}

.wrapper .fab::after {
  width: 18px;
  height: 4px;
}

.wrapper .fac {
  width: 32px;
  height: 150px;
  border-radius: 64px;
  position: absolute;
  box-shadow: var(--box-shadow-inset);
  backdrop-filter: var(--filter-blur);
  backdrop-filter: blur(10px);
  z-index: 2;
  padding: 0.5rem 0.5rem;
  opacity: 0;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition: opacity 0.2s ease-in, top 0.2s ease-in, width 0.1s ease-in;
}

.wrapper .fac button {
  color: #fff;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper .fac button:hover {
  transition: 0.2s;
  opacity: 1;
}

.wrapper input {
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius);
  cursor: pointer;
  position: absolute;
  z-index: 5;
  opacity: 0;
}

.wrapper input:checked~.fab {
  animation: fab-animation 0.4s ease-out forwards;
}

.wrapper input:checked~.fac {
  width: 40px;
  height: 150px;
  animation: fac-animation 0.4s ease-out forwards 0.1s;
  top: 70px;
  opacity: 1;
}

@keyframes fab-animation {
  0% {
    transform: rotate(0) scale(1);
  }

  20% {
    transform: rotate(60deg) scale(0.93);
  }

  55% {
    transform: rotate(35deg) scale(0.97);
  }

  80% {
    transform: rotate(48deg) scale(0.94);
  }

  100% {
    transform: rotate(45deg) scale(0.95);
  }
}

@keyframes fab-animation-reverse {
  0% {
    transform: rotate(45deg) scale(0.95);
  }

  20% {
    transform: rotate(-15deg);
  }

  55% {
    transform: rotate(10deg);
  }

  80% {
    transform: rotate(-3deg);
  }

  100% {
    transform: rotate(0) scale(1);
  }
}

@keyframes fac-animation {
  0% {
    transform: scale(1, 1);
  }

  33% {
    transform: scale(0.95, 1.05);
  }

  66% {
    transform: scale(1.05, 0.95);
  }

  100% {
    transform: scale(1, 1);
  }
}

/* End of Menu Style */

/* Start of Logo Style */
.circle {
  position: relative;
  box-shadow: var(--box-shadow-inset);
  backdrop-filter: var(--filter-blur);
  border-radius: 50%;
  width: 60px;
  /* Circle size */
  height: 60px;
  /* Circle size */
  min-width: 60px;
  /* Minimum width */
  min-height: 60px;
  /* Minimum height */
  max-width: 60px;
  /* Maximum width */
  max-height: 60px;
  /* Maximum height */
  display: flex;
  justify-content: center;
  /* clip-path: circle(50% at 50% 50%); */
  align-items: center;
  z-index: 2;
  /* Ensure the circle is below the Rive animation */
}


.rive-animation {
  position: absolute;
  top: -124px;
  /* Adjust these values to position the desired corner of the animation over the circle */
  left: 1px;
  width: 1180px;
  /* Original width of the Rive animation */
  height: 1180px;
  /* Original height of the Rive animation */
  z-index: 3;
  /* Ensure the animation is above the circle */
}


/* Start of Work/Play Nav */
.switch {
  padding: 5px;

  border: 1px solid rgba(59, 59, 50, 0.2);
  /* Adjust the border color for glass effect */
  border-radius: 35px;
  color: var(--text-color-light);
  box-shadow: var(--box-shadow-inset);
  backdrop-filter: var(--filter-blur);
  backdrop-filter: blur(10px);
  display: flex;
  overflow: hidden;
  z-index: 3;
}

.page-content {
  position: relative;

}

.section-active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* Full width of the container */
  max-width: 1000px;
  /* Adjust as needed */
  margin: 0 auto;
  /* Center the section */
  /* other styles as needed */
}

.section-hidden {
  display: none;
}




/* Adjust the container for the titles if needed */

.menu {
  cursor: pointer;
  color: white;
}

.RiveContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  /* Adjust height as needed */
  width: 80%;

  /* Adjust width as needed */

}

.Cat {
  margin-left: 60px;
  height: 600px;
  width: 90%;
  z-index: 0;
  /* transform: translateY(-7rem); */

}


.Minigame {
  z-index: 100;
  margin-left: 250px;
  height: 600px;
  width: 100%;

}

/* .head {
  margin-left: 10px;
  height: 50vh;
  width: 60%;
} */




.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* Take full width */
}


.tile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* Set width to 100% */
  height: 600px;
  /* Adjust height as needed */
  background-color: inherit;
  transition: background-color 0.3s ease;
}



.title-container {
  padding-top: 20px;
  /* padding-bottom: 30px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  margin: 0 auto;
}

.main-title {
  font-size: 85px;
  color: #f6f6f6;
  margin-top: 40px;
  margin-bottom: 0px;
  text-shadow: 0 0 80px rgb(192 219 255 / 45%), 0 0 32px rgb(65 120 255 / 24%);

}

.subtitle-container {
  
  /* display: flex;
  justify-content: space-between; */
  width: 100%;
  transform: translateY(-10px);
  /* margin-bottom: 1rem; */

}

.increase {
  font-family: 'OpenSauceSansLight', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif !important;
  font-size: 2.6rem !important;
  line-height: 1.3;
  color: #d7d7d7;
  /* transform: translateY(0.3rem); */

  
}

.subtitle-main {
  font-family: 'OpenSauceSans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif !important;
  color: #f6f6f6;
}

.reduce{
  color: #606060;
  font-size: 1rem !important;
  line-height: 1.6;
  /* transform: translateY(-14px); */
}

.subtitle-left,
.subtitle-right {
  font-size: 16px;
  font-weight: 400;
}

.subtitle-right {
  color: #B3A369;
}

.play-title {
  font-weight: 600;
  padding-top: 40px;
  font-size: 22px;
  padding-bottom: 15px;
  /* padding-left: 50px;
  font-size: 20px;
  margin-bottom: 45px; */
}
/* .subtitle-container {
  display: flex;
  align-items: center; 
} */

.bouncing-icon {
  margin-left: 10px;
  display: inline-block;
  animation: myUniqueBounce 1s infinite;
}

@keyframes myUniqueBounce {
  0%, 100% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0px); /* Adjust the bounce height here */
  }
}


.subtitle-smaller {
  text-shadow: 0 0 80px rgb(255, 255, 255), 0 0 32px rgb(255, 255, 255);

  /* color: #ffc0fb; */
  font-size: 16px;
  font-weight: 300;
  margin-top: 10px;
}

.landing-container.play .tile {
  background-color: var(--tile-bg-color-play);
}

.landing-container.work .tile {
  background-color: var(--tile-bg-color-work);
}



.case-studies {
  font-size: 30px;
  font-weight: 500;
  padding-bottom: 15px;
  /* transform: translateY(-5rem); */
  text-shadow: 0 0 80px rgb(192 219 255 / 45%), 0 0 32px rgb(65 120 255 / 24%);


}

.coding-project {
  padding-top: 20px;
  text-shadow: 0 0 80px rgb(192 219 255 /100%), 0 0 32px rgba(173, 196, 254, 0.24);
}


.cards-container {
  /* transform: translateY(-3rem); */
  width: 100%;
  width: 850px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 70px;
}

.card {
  height: 280px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  color: var(--text-color-light);
  box-shadow: var(--box-shadow-inset);
  backdrop-filter: var(--filter-blur);
  /* background: rgb(166,17,17);
  background: linear-gradient(315deg, rgba(166,17,17,0.4) 0%, rgba(0,43,158,0.4) 100%);  */
  border-radius: 3rem;
  padding: 50px;
  margin-bottom: 50px;
}

/* .card-delta {
  background: rgb(166,17,17);
  background: linear-gradient(315deg, rgba(92, 1, 1, 0.6) 0%, rgba(1, 27, 99, 0.4) 100%); 
}

.card-ibm {
  background: rgb(0,17,65);
  background: linear-gradient(315deg, rgba(0,17,65,0.4) 0%, rgba(18, 44, 120, 0.4) 100%); 
}

.card-microsoft {
  background: rgb(26,18,42);
  background: linear-gradient(315deg, rgba(17, 11, 28, 0.4) 0%, rgba(58, 46, 83, 0.4) 100%); 
}

.card-airport {
  background: rgb(43,2,2);
  background: linear-gradient(315deg, rgba(43,2,2,1) 0%, rgba(4,0,0,0.7) 40%); 
} */

.card:hover {
  background-color: #34495500;
  box-shadow: var(--box-shadow-inset-button-hover);
}

.card:hover .card-image {
  transform: scale(1.1);
  transition: transform 300ms ease;
}

.card-text {
  display: flex;
  flex-direction: column;
  /* Stack children vertically */
  justify-content: space-between;
  /* Push the button to the bottom */
  height: 100%;
}

.card-para h3 {
  font-size: 25px;
  padding: 0;
  margin: 0;
}

.card-para p {
  text-align: left;
  line-height: 1.8rem;
  padding-right: 1.2rem;
  margin-top: 1.6rem;
}

.logoCompany {
  display: flex;
}

.microsoft-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  vertical-align: middle;
}


.delta-logo {
  /* width: 30px;
  height: 30px; */
  height: 25px;
  margin-right: 10px;
  vertical-align: middle;
}

.atlogo {
  transform: translateY(0.8rem);
}

.microsoft-title {
  display: inline-block;
  vertical-align: middle;
  /* margin-left: 10px; */
}

.card-image {
  height: 100%;
  /* Ensures the image container matches card height */
  overflow: hidden;
  transition: transform 300ms ease;
  /* Ensures the image does not spill outside the container */
}

.card-image img {
  transform: translateY(-1.6rem);
  height: 310px;
  /* Adjust as needed */
  width: 100%;
  /* Adjust width if needed */
  /* Full height of the container */
  object-fit: cover;
  /* Ensures image covers the area without stretching */
  border-radius: 10px;

}


.edcuration img {
  transform: translateY(1.3rem);
  height: 230px;
  /* Adjust as needed */
  width: 100%;
}

.jampan img {
  transform: translateY(0.8rem);
  height: 260px;
  /* Adjust as needed */
  width: 100%;
}


.freestyle img{
  transform: translate(0.4rem, -0.8rem);
  height: 320px;
  width: 370px;

  /* width: 90%; */
  /* width: 180px; */
}


.mockupmicro img{
  transform: translate(-0.2rem, -2.8rem);
  height: 370px;
  width: 380px;

  /* width: 90%; */
  /* width: 180px; */
}

.laptop img {
  transform: translateY(1.5rem);
  height: 220px;
  /* Adjust as needed */
  width: 100%;
}

.card button {
  background-color: #ff000000;
  box-shadow: var(--box-shadow-inset-button);
  color: var(--text-color-light);
  text-shadow: var(--text-shadow);
  display: flex;
  justify-content: center;
  padding: 1.25rem;
  margin-right: 6rem;
  margin-left: 2rem;
  border-radius: 1.5rem;
  /* Space above the button */
}

.card button:hover {
  background-color: #34495500;
  box-shadow: var(--box-shadow-inset-button-hover);
  /* Darker color on hover */
}

.about-section {

  padding-top: 2rem;
  padding-bottom: 4rem;
  padding-left: 1rem;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  justify-items: center;
}


.about-width {
  max-width: none;
}

.about-row {
  /* align-items: center; */
  justify-content: center;
  justify-items: center;
  display: grid;
  grid-template-columns: 1.3fr 0.7fr;
  /* Two columns of equal width */
}

.image-row {
  /* justify-items: start; */
  padding-left: 50px;
  padding-right: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Three columns */
  gap: 60px;
  /* Space between columns */
}


/* About Section Specific Styles */

.about-title {
  margin-top: 10px;
  padding-bottom: 15px;
  font-size: 40px;
}

.about-text {
  /* Add your text styling here */
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  /* Vertically centers the content */
  text-align: left;
  line-height: 2.2rem;
}

.not-working {
  padding-left: 50px;
  font-size: 20px;
  margin-bottom: 45px;
}

.about-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 280px;
  height: 280px;
  box-shadow: var(--box-shadow-inset);
  backdrop-filter: var(--filter-blur);
}

.about-image {

  width: 220px;
  height: 220px;
  border-radius: 50%;
  /* Makes the image circular */
  object-fit: cover;
  /* Ensures the image covers the area */

}

.image-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Centers the content */
}

.image-description {
  font-style: italic;
  text-align: center;
  margin-top: 20px;
  /* Adjust space between the image and text */
  /* Additional styling */
}


/* Project 1 styling */





.slide1 {
  /* background-color: rgb(18, 67, 122); */
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  /* Align items horizontally */
  justify-content: space-between;
  z-index: 15;
  /* Create space between image and titles */
}


.slide1 img {
  width: 460px;
  display: block;
  margin-left: 70px;
  padding-left: 2.5rem;
  margin-bottom: 2rem;
  /* Add spacing between image and titles */
}

.project-image {
  width: 500px;
  display: block;
  margin-left: 70px;
  animation: rotate-and-slide 1.2s ease-in-out;
}

@keyframes rotate-and-slide {
  0% {
    transform: translateY(20%) rotate(40deg);
    opacity: 0;
  }

  40% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(-6deg);
  }

  70% {
    transform: rotate(6deg);
  }

  80% {
    transform: rotate(-3deg);
  }

  90% {
    transform: rotate(3deg);
  }

  100% {
    transform: translateY(0) rotate(20);
    opacity: 1;
  }
}



.title-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  /* Center horizontally */
  justify-content: center;
  /* Center vertically */
  height: 90%;
  /* Make the container take up the full height of its parent */
}



.title-bg {
  color: #3D3E78;
  background-color: #fdfdfd;
  /* Set the background color to red */
  border-radius: 30px;
  /* Adjust the border-radius to control the roundness of the edges */
  padding: 13px 30px;
  /* Add padding to create space around the text */
  text-align: center;
  /* Center the text horizontally within the red background */
}


.slide1 .MuiTypography-root {
  text-align: left;
  /* Align titles to the left */
  /* font-size: 20px; */
  /* Apply a font size to the first title */
}

.slide1 .MuiTypography-root:nth-child(1) {
  font-size: 20px;
}

.slide1 .MuiTypography-root:nth-child(2) {
  /* line-height: 140%; */
  color: #3D3E78;
  font-weight: 600;
  padding-right: 50px;
  font-size: 43px;
  /* Apply a font size to the second title */
  margin-top: 30px;
  /* Add space between the two titles */
}

.tags {
  display: flex;
  flex-direction: row;
  gap: 8px; /* Adjust the gap between tags as needed */
  margin-top: 0.6rem;
}

/* Style each tag */
.tags p {
  font-size: 13px;
  margin: 0;
  padding: 1px 8px; /* Add some padding around the text for better visuals */
  background-color: rgba(183, 183, 183, 0.163);
  border-radius: 35px;
  white-space: nowrap; /* Prevent text from wrapping */
}












@media (max-width: 768px) {
  .menu-top {
    padding: 35px 10px !important;
  }



  .circle,
  .menu {
    width: 60px;
    height: 60px;
  }

  .btn {
    padding: 5px 10px;
  }

  .RiveContainer {
    height: 50vh;
    /* Adjust for smaller screens */
    width: 90%;
    /* Adjust for smaller screens */
  }

  .card {
    height: auto;
    grid-template-columns: 1fr;
    /* Change to a single column layout */
    text-align: center;
  }

  .card-text {
    order: 2;
    /* Text comes after image */
  }

  .card-title {
    font-size: 20px;
  }

  .card-image {
    order: 1;
    /* Image comes first */
    height: 300px;
    /* Set a fixed height of 300px */


  }

 

}

@media (max-width: 480px) {

  .tile {
    width: 80%;
    height: 150px;
  }

  .RiveContainer {
    height: 40vh;
    /* Adjust for very small screens */
    width: 100%;
    /* Full width on very small screens */
  }
}


