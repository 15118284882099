.cards-container {
  width: 1000px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 70px;
  z-index: 1000;
}

.metallic-card {
  height: 380px;
  display: grid;
  grid-template-columns: 0.98fr 1fr;
  align-items: start;
  color: var(--text-color-light);
  position: relative;
  border: 2px solid transparent;
  background: linear-gradient(71deg, #080509, #1a171c, #080509);
  background-clip: padding-box;
  border-radius: 45px;
  padding: 0;
  margin-bottom: 75px;
  cursor: pointer;
  overflow: hidden;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1),
              transform 1s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform, opacity;
}

.metallic-card.slide-up-active {
  opacity: 1;
  transform: translateY(0);
}

/* Base metallic effect - always visible */
.metallic-card::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 45px;
  padding: 1px;
  background: linear-gradient(71deg, #080509, #5c5456, #080509);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  transition: background 0.3s ease;
  z-index: 2;
}

.metallic-card:hover::before {
  background: linear-gradient(71deg, #080509, var(--accent-color), #080509);
}

/* Glow effect - only on hover */
.metallic-card::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  background: radial-gradient(
    70% 70% at 50% 0%,
    rgba(var(--accent-color-rgb), 0.15) 0%,
    rgba(var(--accent-color-rgb), 0) 100%
  );
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 3;
}

.metallic-card:hover::after {
  opacity: 1;
}

/* Rest of your existing CSS stays exactly the same */
/* Hover effects container */
.hover-effects {
  position: absolute;
  inset: 0;
  border-radius: 45px;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  -webkit-mask-image: radial-gradient(circle at 60% 5%, black 0%, black 15%, transparent 60%);
  mask-image: radial-gradient(circle at 60% 5%, black 0%, black 15%, transparent 60%);
  z-index: 1;
}

/* Grid background */
.gird-container-metalicard {
  /* position: absolute; */
  /* top: 40px;
  left: 40px;
  width: 200px;
  height: 200px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 4px; */
}

.grid-cell {
  width: 100%;
  height: 100%;
  background: transparent;
  transition: opacity 0.3s ease;
}

.grid-cell.colored {
  background: rgba(255, 255, 255, 0.05);
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0%, 100% { opacity: 0.8; }
  50% { opacity: 0.3; }
}

/* Specific colored cells */
.grid-cell:nth-child(1),
.grid-cell:nth-child(3),
.grid-cell:nth-child(6),
.grid-cell:nth-child(8),
.grid-cell:nth-child(9),
.grid-cell:nth-child(11) {
  background: rgba(255, 255, 255, 0.05);
}

/* Staggered animations */
.grid-cell:nth-child(1) {
  animation-delay: 0s;
}

.grid-cell:nth-child(3) {
  animation-delay: -0.5s;
}

.grid-cell:nth-child(6) {
  animation-delay: -1s;
}

.grid-cell:nth-child(8) {
  animation-delay: -1.5s;
}

.grid-cell:nth-child(9) {
  animation-delay: -2s;
}

.grid-cell:nth-child(11) {
  animation-delay: -2.5s;
}

/* Lines */
.line {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  transition: transform 0.4s ease;
}

.line-horizontal {
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transform-origin: left;
}

.line-vertical {
  width: 1px;
  height: 100%;
  transform: scaleY(0);
  transform-origin: top;
}

/* Hover states */
.metallic-card:hover .hover-effects {
  opacity: 1;
}

.metallic-card:hover .gird-container-metalicard {
  opacity: 1;
}

.metallic-card:hover .line-horizontal {
  transform: scaleX(1);
}

.metallic-card:hover .line-vertical {
  transform: scaleY(1);
}

/* Content styles */
.logo-title {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  position: relative;
  z-index: 2;
}

.logo-wrapper {
  position: relative;
  width: 40px;
  height: 40px;
  background: linear-gradient(71deg, #080509, #1a171c, #080509);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.logo-wrapper::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 1px;
  background: linear-gradient(71deg, #080509, var(--accent-color), #080509);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  transition: background 0.3s ease;
}

.logo-wrapper::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 12px;
  background: radial-gradient(circle at center,
      color-mix(in srgb, var(--accent-color) 20%, transparent),
      transparent 70%);
  pointer-events: none;
}

.company-logo {
  width: var(--logo-size);
  height: var(--logo-size);
  object-fit: contain;
  position: relative;
  z-index: 1;
}

.company-title {
  margin: 0;
  font-size: 28px;
  color: white;
  position: relative;
  z-index: 2;
}


.card-text {
  padding: 50px 0 50px 50px;
  /* Keep left padding for text content */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px 0;
}

.tags p {
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 12px;
  border-radius: 20px;
  color: white;
  font-size: 14px;
  margin: 0;
}

.card-image {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-right: -2px;
  z-index: 1; 
  /* Compensate for border */
}

.card-image img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  z-index: 1;
  /* Round only left corners */
}

/* Specific styles for the Delta card */
.card-image-delta {
  border-radius: 30px; /* Round all edges of the container */
  overflow: hidden; /* Ensure the image respects the rounded edges */
  display: flex;
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
}

.card-image-delta img {
  border-radius: 30px; /* Round all edges of the image */

  object-fit: contain; /* Ensure the image fits within the container */
}
.see-project-button {
  margin-top: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  padding: 12px 24px;
  border-radius: 24px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  /* align-self: flex-start; */
}

.see-project-button:hover {
  background: rgba(144, 34, 34, 0.2);
}