/* Apply Flexbox layout to the page content */

:root {
  --box-shadow-inset: inset 0 22px 56px -36px rgba(255, 255, 255, 0.069),
    inset 0 4px 5px -4px rgba(255, 255, 255, 0.12),
    inset 0 -31px 34px -32px rgba(96, 68, 144, 0.218),
    inset 0 39px 50px -34px rgba(202, 172, 255, .3),
    inset 0 2px 9px rgba(155, 146, 210, 0.172),
    inset 0 1px 10px rgba(227, 222, 255, 0.129);


  --filter-blur: blur(100px);


}


.content-wrapper {
  margin-top: 20rem;
  display: flex;
  justify-content: center; /* Align children to the start of the container */
  /* align-items: flex-start;  */
  /* margin-right: 13rem; */
}

.floating-container {
  /* Your existing styles */
  top: 300px; /* Adjust 'top' as necessary to position your sidebar */
  margin-right: 0px; /* Add some space between the floating container and the project overview */
  margin-top: 6rem;
  /* padding-top: 2rem; */
  padding-bottom: 2rem;
  height: auto; /* Example height */
  box-shadow: var(--box-shadow-inset);
  backdrop-filter: var(--filter-blur);
  background-color: rgba(232, 235, 250, 0.5);
  border-radius: 50%;
  border-radius: 20px;
  width: 180px; /* Your desired width */
  z-index: 10; /* or any number higher than the content it needs to stay above */

}

.info-case-grid{
  text-align: left !important;
  padding: 0;
  display: flex;
  justify-content: space-between;
  /* padding: 0 5rem; */
  /* width: 700px; */
  /* gap: 10px; */
  /* place-items: center;  */
}

/* .team-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
} */

.role-uxdr {
  text-align: center !important;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.info-case-grid p {
  text-align: left !important;
  line-height: 1.75;
  /* text-align: left !important; */
  font-size: 0.9rem;
  padding: 0;
  margin: 0.2rem 0 0 0;
}

.process p {
  margin: 0;
  margin-bottom: 2rem;
  font-size: 0.9rem;
  line-height: 1.75;
}

.role-uxdr p{
  padding: 0;
  margin: 0;
  text-align: left !important;
  line-height: 1.75;
  font-size: 0.9rem;
  

  
}

.collapsible-header {
  display: flex;
  justify-content: space-between; /* This will push the content to the left and the icon to the right */
  align-items: center;
  width: 100%; /* Ensure the container takes full width */
}


.icon-circle-background {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Circle shape */
  width: 13px; /* Circle size */
  height: 13px; /* Circle size */
  color: #505050; /* Icon color */
  border-width: 1px;
  border-style: solid;
  border-color: #505050;
  margin-right: 1rem;
  transform: translateY(0.7rem);

}

.icon-circle-background .fa-icon {
  font-size: 7px; /* Smaller icon size */
  
}




.custom-bullet.index {
  width: 5px;
  height: 5px;
  border-radius: 35%;
  margin-left: 1.5rem;
  margin-top: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center; /* Ensure vertical alignment if needed */
}

.index-title p {
  font-size: 14px;
  margin-top: 1.5rem;
  /* transform: translateY(0.4rem); */

  margin-left: 1rem;
  font-weight: 600;
  color: #3d3e78 !important;
}

.custom-bullet.index p {
  font-size: 12px;
  margin-left: 1rem; /* Remove default margin to avoid unwanted spacing */
  white-space: nowrap;
}

.indextitlecolla p {
  font-weight: 600;
}


/* CSS file: LandingPage.css or ProjectMicro.css */
.icon-rotate {
  transform: rotate(-90deg); /* Rotate the icon for the collapsed state */
}



.icon-rotate {
  transition: transform 0.3s ease-in-out;
  transform: rotate(0deg); /* Default state */
}

.icon-rotate.open {
  transform: rotate(90deg); /* Adjust the rotation degree as needed */
}

.collapsible-content {
  padding-bottom: 0.3rem;
}

.subindex {
  background-color: hwb(0 60% 40% / 0.274) !important;
}

.active-class p{
  color: #DF3A6D !important;/* Just an example to highlight the active section */
  font-weight: 700;
}




/* .floating-container {
  position: fixed;
  border-radius: 35px;
  top: 50%;
  transform: translateY(-50%);
  width: 200px;
  height: 400px;
  background-color: rgba(105, 104, 164, 0.607);
  z-index: 1000; 
} */


.project p {
  /* text-align: justify; */
  color: #505050;
}

.items {
  font-size: 16px;
}

.item-text {
  /* text-align: center; */
  font-size: 18px;
  font-weight: 700;
  color: #3D3E78;
}






.project1-section2 {
  padding-top: 40px;
  color: #100f24;
  width: 100%;
  height: 100%;
  /* Adjust the height of the fade effect as needed */
  background: white;


}

.grid-container-context {
  /* text-align: center; */

  padding-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
  padding-bottom: 1rem;
}

.grid-container-context p {
  text-align: left;
}

.grid-container {

  text-align: center;
  /* text-align: center; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 100px;
  padding: 50px;
  /* padding-bottom: 2rem; */
  /* Adjust the gap between the columns */
}

.involved {
  padding-top: 10px;
  padding-bottom: 10px;
}

.grid-solution {
  margin-top: 17rem;
  margin-bottom: 30px;
  display: grid;
  flex-direction: column;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  background-color: #3D3E78;
  border-radius: 45px;
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
  height: 420px;
}

.designThinking img {
  max-height: 100%;
  max-width: 85%;
  object-fit: contain;
  padding-top: 1rem;
  padding-bottom: 2.5rem;
  /* padding-bottom: 40px; */
}

.phone {
  transform: translateY(-16rem);
}

.phone img {

  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  /* padding-bottom: 3rem; */
}

.phone p {
  padding: 0px 5px;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: #E8EBFA;
  line-height: 1.3rem;
}

.hmw img {
  max-height: 60%;
  max-width: 80%;
  object-fit: contain;
  /* padding-bottom: 1rem; */
}


.grid-solution-phone {

  text-align: left;
  /* text-align: center; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* gap: 0px; */
  /* padding: 50px; */
  /* padding-bottom: 2rem; */
  /* Adjust the gap between the columns */
}
.flex-direc {
  margin-top: 1.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle-wrapper {
  align-self: flex-start;
  display: flex; /* Ensure items are inline */
  align-items: center; /* Align items vertically */
  margin-bottom: 0.5rem;
}

.circle-solution-number {
  margin-left: 60px;
  /* margin-top: 0px; */
  /* margin-bottom: 10px; */
  font-weight: 800;
  width: 35px; /* Size of the circle */
  height: 35px; /* Size of the circle */
  border-radius: 50%; /* Make it round */
  background-color: #515390; /* Circle color */
  color:#E8EBFA; /* Text color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px; /* Adjust the font size of the number as needed */
}

.circle-title {
  margin-left: 0.7rem;
  font-size: 20px; /* Size */
  color: #4C4E86; /* Color */
  font-weight: 700; /* Font weight */
}


.number-on-right {
  margin-right: 20px;
}
.phonemockup img {
  /* transform: scale(1.1); */
  /* max-height: 100%;
  max-width: 100%; */
  max-width: 90%;
  object-fit: contain;
}

.grid-solution-phone p {
  text-align: justify;
  padding-left: 60px;
}

.otherside {
  grid-template-columns: 1fr 1fr;
}

.otherside p {
  padding-right: 20px;
}

/* .first-frame {
  padding-top: 30px;
  padding-right: 38px;
  padding-left: 70px;
} */

.center-title {
  /* font-size: 1px; */
  display: flex;
  font-size: 13px;
  justify-content: center;
  padding-bottom: 10px;
  margin-top: 4rem;
}

.context-main {
  margin-top: 0rem;
  padding-top: 0.5rem;
  /* justify-content: left; */
}

.context {
  padding-bottom: 1px;
}

/* Add this to your existing CSS */

.red-background {
  background-color: #E8EBFA;
  height: 19px;
  /* Change to your desired red color */
  border-radius: 25px;
  /* Adjust the border-radius for rounded edges */
  padding: 0px 20px;
  /* Add padding to the background */
  position: relative;
  top: -5px;
  /* Adjust the position 5px up */
  display: inline-block;
  /* Ensure it takes the width of its content */
}

.context {
  color: #100f24;
  background-color: white;
  font-size: 10px;
}

.full-text-context {
  transform: translate(-20px, 0px);
}


.red-text {
  font-weight: 600;
  color: #3D3E78;
}

.full-text {
  font-weight: 400;
  transform: translateY(-35px);

}


.users img {
  max-height: 20%;
  /* Sets the max height to 80% of its container */
  max-width: 20%;
  /* Ensures the image does not exceed the container's width */
  object-fit: contain;
}

.sketchpro {
  max-height: 100%;
  max-width: 90%;
  object-fit: contain;
}

.small-icon-image {


  margin-left: auto;
  margin-right: auto;
  display: block;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transform: translateX(-15px);

}

.third-frame {
  color: #100f24;
  font-weight: 400;
}

.third-frame-main {
  font-weight: 600;
  color: #3D3E78;
}

.header-story {
  font-weight: 500;
  color: #4c4c4c;
  font-size: 24px;
  margin-top: 2rem;
  /* padding-top: 1rem; */
}

.bullet-point {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.bullet {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #3d3e78;
  border: 5px solid #E8EBFA;
  margin-right: 10px;

}

.method {
  font-size: 20px !important;
  font-weight: 300;
  padding-bottom: 3px;
  color: #717171;

}

.project-paragraph {
  /* margin-left: 0.5rem; */
  line-height: 2.1rem;
  transform: translateY(-15px);
}

.contextOfUse {
  margin-left: 0rem;
  transform: translateY(0px);
}

.placeholder-images-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Creates two columns of equal width */
  gap: 40px;
  /* Sets the gap between grid items */
  /* margin-bottom: 20px; */
  /* Keeps your existing bottom margin */
}

.placeholder-image-column {
  display: flex;
  align-items: center;
  /* Centers content vertically */
  justify-content: center;
  /* Centers content horizontally */
  height: 100%;
  /* Ensures the column takes full height of its container */
}

.pieOne img {
  max-height: 80%;
  /* Sets the max height to 80% of its container */
  max-width: 100%;
  /* Ensures the image does not exceed the container's width */
  object-fit: contain;
  /* Maintains aspect ratio and fits the content within the container */
}

.questionOne img {
  max-height: 53%;
  /* Sets the max height to 80% of its container */
  max-width: 100%;
  /* Ensures the image does not exceed the container's width */
  object-fit: contain;
  /* Maintains aspect ratio and fits the content within the container */
}


.questionTwo img {
  /* max-height: 54%; */
  margin-left: 2rem;
 
}


.placeholder-image {

  margin-left: auto;
  margin-right: auto;
  display: block;
  /* display: flex; */
  align-items: center;
  /* Centers content vertically */
  justify-content: center;
  width: 100%;
  /* Set the width */
  height: 100%;
  /* Set the height */
  border-radius: 10px;
  /* Add rounded corners */
  margin-top: 20px;
  /* Adjust margin as needed */
}

.chart img {
  width: 90%;
  margin-bottom: 3rem;
}

.table img {
  width: 100%;
}


.custom {
  /* padding-left: 10px; */
  display: block;
  /* or 'flex' depending on layout needs */
  padding-right: 4rem;
  /* Adjusted padding */
  /* line-height: 1.5rem; */
  /* Consistent line height */
}

.custom-bullet-point p {
  line-height: 1.5; /* Adjust the line height as needed */
}



.custom-bullet-point {

  display: flex;
  align-items: flex-start;
  /* Align items at the start, better for varying text lengths */
  /* margin-bottom: 10px; */
  /* Added to create space between bullet points */
}

.custom-bullet {
  flex-shrink: 0;
  width: 9px;
  height: 9px;
  border-radius: 35%;
  background-color: hwb(239 24% 53%);
  margin-right: 15px;
  margin-top: 1.5rem;
}

.stakeholders .custom-bullet {
  margin-top: 32px;
}

.stakeholders .custom-bullet-point {
  margin-top: 2.5rem;
  margin-bottom: 0px;
}

.stakeholders p {
  line-height: 2.5rem;
  /* Improved line height for readability */
  margin-bottom: 1rem;
  /* Adds space below each paragraph for separation */
}

.rightholder {
  margin-top: 1rem;
}

.rightholder  .custom-bullet-point {
  margin-top: 0rem;
  margin-left: 30px;
}




.target {
  position: relative; /* Ensure it's positioned */
  overflow: visible; /* Make sure overflow is visible */
}

.target::before {
  content: "";
  position: absolute;
  top: 0%; /* Adjust these values as needed to control the overflow */
  left: -10%;
  right: -10%;
  bottom: -60%;
  background-image: url('../src/assets/project1/focus.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}







.rightholder p {
  /* padding-top: 1.2rem; */
  margin-top: 35px;
  line-height: 0rem;
}


.key-insight-title {
  color: #3D3E78;
  font-size: 23px;
  font-weight: 600;
  padding-bottom: 1rem;

}


.key-insights {
  border-radius: 35px;
  margin-top: 1rem;
  margin-bottom: 5rem;
  padding-top: 30px;
  padding-bottom: 15px;
  text-align: left;
  padding-left: 50px;
  /* width: 100vw; */
  position: relative;
  height: 100%;
  /* left: calc(-50vw + 50%); */
  background-color: #E8EBFA;
  /* or any other background color you prefer */
}

.bullet-finding {
  transform: translateY(-4px);
  width: 40px;
  height: 40px;
  background-color: #E8EBFA;
  /* or any other background color you prefer */
}

.finding {
  font-weight: 600;
  font-size: 25px;
}

.finding p{
  padding-bottom: 0;
  margin-bottom: 1rem;

}

.interview-container {
  display: grid;
  grid-template-columns: 2.1fr 0.9fr;
  gap: 40px;
}

.interviewPic img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 4.5rem;
}

.finding-bullet {
  /* margin-left: 60px; */
  /* margin-bottom: 10px; */
  transform: translateY(24px);
  margin-right: 1.5rem;
  font-weight: 800;
  width: 45px; /* Size of the circle */
  height: 45px; /* Size of the circle */
  border-radius: 50%; /* Make it round */
  background-color: #E8EBFA; /* Circle color */
  color:#3D3E78; /* Text color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px; /* Adjust the font size of the number as needed */
}

.small-finding {
  transform: translateY(-29px);
  padding-left: 4.3rem;
  font-weight: 400;
  /* padding-bottom: 10px; */
}

.key-findings {
  margin-top: 1.5rem;
  margin-bottom: 5rem;
  background-color: #3D3E78;
  /* or any other background color you prefer */
}

.key-findings p {
  color: #E8EBFA !important;
}

.user-needs{
  padding-left: 3.5rem;
  margin-bottom: 3rem;
}

.chevron {
  transform: translateY(1px);
  color: #5B5FC7;
  font-size: 18px;
  margin-right: 1rem;
}

.user-need {
  color: #5B5FC7;
  font-weight: 700;
  
}

.findingsmove {
  transform: translateY(7px);
}

.empathy img {
  padding: 20px 0px;
  width: 80%;
}

iframe {
  margin-bottom: 2rem;
}
.matrix {
  text-align: center;
  margin-top: 0;
}
.matrix img {
  width: 90%;
  transform: translateY(-1rem);
  /* margin-bottom: 2rem; */
}

.grid-container-sketches {
  /* padding-top: 1rem; */
  align-items: stretch;
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  gap: 60px;
  /* padding-bottom: 1rem; */
}

.initial {
  text-align: right;
  /* margin-left: 5rem; */
}
.initial img {
  width: 100%;
  border-radius: 1rem;
}


.grid-sketches {

  text-align: center;
  align-items: center;
  /* text-align: center; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  padding-top: 2rem;
  padding-bottom: 3rem;

  /* gap: 0px; */
  /* padding: 50px; */
  /* padding-bottom: 2rem; */
  /* Adjust the gap between the columns */
}

.sketch-first {
  grid-template-columns: 1fr 1fr ;
}

.sketches img {
  max-width: 100%;
}

.scenario {
  font-style: italic;
  color: #5B5FC7 !important;
  padding: 1.5rem 6rem;
}

.designsys img {
  max-width: 100%;
}

.prototypes {
  /* padding: 5rem; */
  text-align: center;
  background-color: #e8ebfa4a;
  border-radius: 30px;
  margin-bottom: 4rem;

}

.prototypes img {
  width: 100%;
  
}

.explanation {
  text-align: center;
  /* background-color: #E8EBFA; */
  border-radius: 40px;
  padding-bottom: 3rem;
}

.title-eval {
  font-weight: 700;
  transform: translateY(-0.4rem);
}

.test-grid {
  
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
  margin-bottom: 3rem;
}

.test-grid img {
  margin-top: 3rem;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  border-width: 1px;
  border-style: solid;
  border-color: #8c8c8c;
  border-radius: 20px;
}

.process-tasks {
  margin-top: 1.5rem;
}

.process-tasks p {
  font-weight:600;
  padding-left: 1rem;
  color: #4C4E86;
  font-size: 12px;
  line-height: 1.5;
}