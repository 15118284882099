:root {
  --box-shadow-inset-2: inset 0 22px 56px -36px rgba(255, 255, 255, 0.069),
    inset 0 4px 5px -4px rgba(255, 255, 255, 0.12),
    inset 0 -31px 34px -32px rgba(144, 112, 68, 0.218),
    inset 0 39px 50px -34px rgba(246, 194, 91, 0.3),
    inset 0 2px 9px rgba(210, 187, 146, 0.172),
    inset 0 1px 10px rgba(255, 244, 222, 0.129);
}

/* .background-atl {
  background: linear-gradient(to bottom, #d2953f, white);
} */

.red-text-atalnta {
  color: #d2953f;
}

.active-class-atl p {
  color: rgb(29, 99, 189) !important;
}

.content-wrapper-atl {
  margin-top: 19rem;
}

.floating-container-atl {
  box-shadow: var(--box-shadow-inset-2);
  background-color: rgba(250, 234, 210, 0.223);
}

.custom-bullet-atl {
  background-color: #c37406;
}

.index-title-atl p {
  color: #c37406 !important;
}


.item-text-atl {
  color: #c37406 !important;
}

.process-atl {
  margin-top: 2.5rem;
}

.orange-bg {
  background-color: #ffefd9;
}

.context {
  background-color: white;
}

.red-text-atalnta {
  color: #d2953f;
}

.slide2 .MuiTypography-root:nth-child(2) {
  color: #d2953f;
}

.slide2 img {
  width: 75%;
  margin-top: 2rem;
  padding-left: 3rem;
  margin-bottom: 2rem;
  /* transform: translateX(2rem); */
}

.title-bg-atl {
  color: #b27b2e;
}

.bullet-atl {
  background-color: #d2953f;
  border: 5px solid #ffefd9;
}

.problemspace {
  padding: 0;
  margin-bottom: 3rem;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  /* text-align: center !important; */
}
.caption-images p {
  font-style: italic;
  padding-top: 1.8rem !important;
  margin-bottom: 2rem !important;
  text-align: center !important;
  /* transform: translateY(1rem); */
}
.research-method-title {
  padding-bottom: 1rem;
  /* line-height: 1.5; */
}

.research-method-title p {
  text-align: center;
  color: #d2953f;
  font-weight: 700;
  padding: 0;
  margin: 0.5rem;
  font-size: 19px;
}

.problem {
  align-content: center;
  text-align: center !important;
  padding: 1.5rem;
  height: 190px;
  background-color: #ffefd9;
  border-radius: 35px;
}

.problem p {
  line-height: 2;
  font-size: 15px;
  text-align: center !important;
  /* padding: 0.5rem; */
  /* text-align: ju; */
}

.stakeholder-atl {
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  gap: 60px;
  /* justify-content: center !important; */
  align-items: center !important;

}

.stakeholder-atl p {
  transform: translateY(0.5rem);
}


.stake-img img {
  width: 100%;
}

.timeline img {
  margin-top: 4rem;
  width: 80%;
}

.entire-thing {
  transform: translateY(-4rem);
}

.solution-grid img {
  width: 100%;
  transform: translateY(5rem);
}

.solution-grid video {
  width: 100%;
  transform: translateY(5rem);
}

.solution-grid {
  padding:  0rem 1rem 0rem 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

.solution-grid-para {
  padding:  5rem 1rem 1rem 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  background-color: #fff5e7;
  border-radius: 35px;
}

.solution-number {
  display: flex;
  flex-direction: column;
}
.caption-solu {
  margin-top: 2.5rem !important;
  line-height: 1.5rem !important;
  text-align: left !important;
  font-size: 12px !important;
}


.findings-grid-atl {

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
  text-align: left;
  line-height: 1rem;

}


.bullet-atl-finding p {
  line-height: 2rem;
  font-size: 19px;

}

.bullet-atl-finding {
  text-align: center !important;
}

.small-finding-atl {
  line-height: 1.8rem;
  font-size: 15px;

}

.user-need-para {
  padding: 0;
  margin: 0;
  margin-left: 7.8rem;
  transform: translateY(-0.8rem);
}

.finding-wrapper-atl {
  margin-bottom: 2rem;
}

.finding-bullet-atl {
  /* margin-left: 3.7rem; */
  margin-bottom: 0.5rem;
  font-size: 16px;
  text-align: center;
  width: 35px;
  height: 35px; 
  background-color: #d2953f;
  color: #f7f7f6;
}

.solution-bullet {
   margin-left: 3.7rem;
}

.user-needs-atl {
  text-align: center;
  padding-left: 5rem;

}

.user-need-bullet {
  width: 45px;
  /* margin-right: 2rem; */
  transform: translateY(1rem);
  text-align: left;
  font-size: 14px;
  color: #8c8c8c;
  background-color: white;
  margin-left: 3.5rem;

  /* width: 35px;
  height: 35px; */
}

.design-metaphor {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
  text-align: left;
  line-height: 1rem;
  justify-content: center !important;
  align-items: center !important;
}

/* .finding-title-atl {
  margin-left: 0.7rem;
  line-height: 1.8rem;
  font-size: 16px; 
  color: #505050; 
  font-weight: 700; 
} */

.artifact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px;
  row-gap: 0;
  text-align: center;
  justify-content: center;
  align-items: center;

}

.bg-frame {
  width: 100%;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #8c8c8c;
  margin-bottom: 3rem;
}

.miro-frame {
  transform: translateY(-0.3rem);
  clip-path: polygon(1% 3%, 99% 3%, 99% 100%, 1% 100%);
}

.bg-frame-persona {
  width: 340px;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #8c8c8c;
  transform: translateX(6rem)
}

.miro-frame-persona {
  transform: translateY(1rem);
  clip-path: polygon(4% 0%, 96% 0%, 96% 100%, 4% 100%);
}

.brainStorming img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  /* padding-top: 1rem;
  padding-bottom: 2.5rem; */
  border-radius: 35px;
  /* padding-bottom: 40px; */
}

.concepts-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
  row-gap: 0;
  text-align: left;

}

.concept-title {
  font-size: 1.09rem;
  color: #d2953f;

}

.concept-image {
  margin-bottom: 2rem;

}

.concept-image img {
  border-width: 1px;
  border-style: solid;
  border-color: #8c8c8c;
  border-radius: 25px;
  padding: 0 !important;
  margin: 0 !important;
}

.caption {
  font-size: 14px;
  line-height: 1.8rem;
  text-align: left;
  padding: 0;
  margin: 0;
}

.storyboard-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  row-gap: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.caption-storyboard {
  font-size: 13px;
  line-height: 1.4rem;
  text-align: left;
  padding: 0 !important;
  margin: 0 !important;
  transform: translateY(-1.5rem);
}

.concepts-combined-grid {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 0.5fr 1fr ;
  gap: 10px;
}

.plus img {
  width: 100%;
  margin-top: 2.8rem;

}

.prototypes-atl {
  background-color: white;
}