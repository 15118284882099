/* Apply Flexbox layout to the page content */
@font-face {
    font-family: 'OpenSauceSans';
    src: url('../public/fonts/OpenSauceSans-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSauceSansLight';
    src: url('../public/fonts/OpenSauceSans-Light.ttf') format('truetype');

}

@font-face {
    font-family: 'OpenSauceSansMedium';
    src: url('../public/fonts/OpenSauceSans-Medium.ttf') format('truetype');

}

@font-face {
    font-family: 'OpenSauceSansBold';
    src: url('../public/fonts/OpenSauceSans-Bold.ttf') format('truetype');

}

@font-face {
    font-family: 'OpenSauceSansLightItalic';
    src: url('../public/fonts/OpenSauceSans-LightItalic.ttf') format('truetype');

}


:root {
    --box-shadow-inset: inset 0 22px 56px -36px rgba(255, 255, 255, 0.069),
        inset 0 4px 5px -4px rgba(255, 255, 255, 0.12),
        inset 0 -31px 34px -32px rgba(96, 68, 144, 0.218),
        inset 0 39px 50px -34px rgba(202, 172, 255, .3),
        inset 0 2px 9px rgba(155, 146, 210, 0.172),
        inset 0 1px 10px rgba(227, 222, 255, 0.129);
    --filter-blur: blur(100px);


}





.delta-card-wrapper .metallic-card .card-text p {
    color: rgb(156, 160, 171) !important;
}

.delta-card-wrapper .metallic-card .tags p {

    color: white !important;
}




.content-wrapper {
    /* margin-top: 20rem; */

    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    /* Align children to the start of the container */
    /* align-items: flex-start; */
    /* Align children to the top of the container */
    /* margin-right: 13rem; */
}

.item-text {
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
}

.team-grid {
    display: grid;
    grid-template-columns: 1fr;
}

.metallic-card-indiv {
    margin-bottom: 15px;
}

.process {
    margin-top: 24px;
    width: 100%;
}

.delta-process {
    margin-top: 40px !important;
}

.role-uxdr {
    display: grid;
    gap: 16px;
    width: 100%;
}

.animate__animated {
    animation: fadeIn 0.9s ease-in;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* .team-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  } */

.role-uxdr {
    text-align: center !important;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}

/* .info-case-grid p {
    text-align: left !important;
    line-height: 1.75;
    font-size: 0.9rem;
    padding: 0;
    margin: 0.2rem 0 0 0;
} */

.make-it-mediam {
    font-family: 'OpenSauceSansBold', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif !important;
    color: #6614149c;

}

.make-it-bold {
    font-family: 'OpenSauceSansBold', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif !important;

}


.info-case-grid {
    text-align: left !important;
    /* padding: 0; */
    display: flex;
    justify-content: space-between;
    /* padding: 0 5rem;
   */
    place-items: center;
}

.process p {
    margin: 0;
    margin-bottom: 2rem;
    font-size: 0.9rem;
    line-height: 1.75;
}

.role-uxdr p {
    padding: 0;
    margin: 0;
    text-align: left !important;
    line-height: 1.75;
    font-size: 0.9rem;



}

.collapsible-header {
    display: flex;
    justify-content: space-between;
    /* This will push the content to the left and the icon to the right */
    align-items: center;
    width: 100%;
    /* Ensure the container takes full width */
}


.icon-circle-background {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    /* Circle shape */
    width: 13px;
    /* Circle size */
    height: 13px;
    /* Circle size */
    color: #505050;
    /* Icon color */
    border-width: 1px;
    border-style: solid;
    border-color: #505050;
    margin-right: 1rem;
    transform: translateY(0.7rem);

}

.icon-circle-background .fa-icon {
    font-size: 7px;
    /* Smaller icon size */

}









.indextitlecolla p {
    font-weight: 600;
}


/* CSS file: LandingPage.css or ProjectMicro.css */
.icon-rotate {
    transform: rotate(-90deg);
    /* Rotate the icon for the collapsed state */
}



.icon-rotate {
    transition: transform 0.3s ease-in-out;
    transform: rotate(0deg);
    /* Default state */
}

.icon-rotate.open {
    transform: rotate(90deg);
    /* Adjust the rotation degree as needed */
}

.collapsible-content {
    padding-bottom: 0.3rem;
}

.subindex {
    background-color: hwb(0 60% 40% / 0.274) !important;
}

.active-class p {
    /* Just an example to highlight the active section */
    font-weight: 700;
}




/* .floating-container {
    position: fixed;
    border-radius: 35px;
    top: 50%;
    transform: translateY(-50%);
    width: 200px;
    height: 400px;
    background-color: rgba(105, 104, 164, 0.607);
    z-index: 1000; 
  } */


/* .info-case-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;
    color: white;
} */

.item-text {
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
}

/* Add color to all paragraph elements */
.info-case-grid p,
.process p,
.role-uxdr p {
    color: rgb(194, 194, 194);
}

.team-grid {
    display: grid;
    grid-template-columns: 1fr;
}

.team-grid p {
    color: rgb(194, 194, 194) !important;
}

.process {
    margin-top: 24px;
    width: 100%;
    color: white;
}

.role-uxdr {
    display: grid;
    gap: 16px;
    width: 100%;
    color: white;
}

.animate__animated {
    animation: fadeIn 0.9s ease-in;
}

/* Ensure all text within these sections is white */
.project-container * {
    color: rgb(255, 255, 255);
}



@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.delta-feature-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
    /* margin-bottom: 3rem; */
    /* padding: 20px; */
}

.delta-feature-column {
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
}

.delta-feature-header {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 5px;
}

.llearned-adjust {
    align-items: flex-start;
}

.learned-circle {
    margin-top: 0.4rem;
}

.learned-para {
    /* font-size: 18px !important; */
}

/* Default inactive state for circle */
.delta-solution-circle {
    width: 35px;
    height: 35px;
    min-width: 35px;
    background-color: #cecece;
    /* Grey background for inactive */
    color: rgb(72, 72, 72);
    /* Black text for inactive */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    z-index: 2;
    flex-shrink: 0;
    transition: background-color 0.3s, color 0.3s;
}

.Impact-circle {
    background-color: #E79C9C;
    color: #5E0909;
}

.delta-feature-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* vertical center */

    height: 100%;
    /* fill the parent container height */
}

.delta-features-container-impact {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1px;
    margin-left: 4rem;
}

/* Active state for circle */
.delta-feature-column.active .delta-solution-circle {
    background-color: #E79C9C;
    /* Red background for active */
    color: rgb(145, 57, 57);
    /* White text for active */
}

.delta-feature-column.active .delta-feature-title {

    /* Red background for active */
    color: #ae5959;
    /* White text for active */
}



.delta-feature-title {
    margin: 0;
    line-height: 1.6;
    font-weight: 700;
    color: rgb(144, 144, 144);
    font-size: 1.3rem;
    display: flex;
    align-items: center;
}


.Impact-title {
    margin: 0;
    line-height: 1.6;
    font-weight: 500;
    color: #333;
    font-size: 1rem;
    /* display: flex; */
    align-items: center;
}



.delta-video-wrapper {
    position: relative;
    width: 90%;
    margin: 3rem;

}

.delta-video-container {
    /* width: 350px;
    height: 620px; */
    height: 585px;
    border-radius: 40px;
    overflow: hidden;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    position: relative;
    z-index: 1;
    cursor: pointer;

}

.delta-video-container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Default inactive state for background */
.delta-image-background {
    position: absolute;
    right: -5px;
    bottom: -5px;
    /* bottom: 90px; */
    width: 100%;
    height: 585px;
    background-color: #e1dede;
    /* Grey background for inactive */
    border-radius: 40px;
    z-index: 0;
    transition: background-color 0.3s;
}

/* Active state for background */
.delta-feature-column.active .delta-image-background {
    background-color: #E79C9C;
    /* Red background for active */
}

.delta-feature-description {
    font-family: 'OpenSauceSans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
    padding-right: 1rem;
    padding-left: 2.6rem;
    line-height: 2;
    color: rgb(172, 169, 169) !important;
    font-size: 1.4rem;
    text-align: left;

}

.read-more,
.read-less {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: #8e1827;
    margin-left: 5px;
    transition: color 0.2s;
}

.read-more:hover,
.read-less:hover {
    color: #8e1827;
    text-decoration: underline;
}

.read-more svg,
.read-less svg {
    margin-top: 1px;
    margin-left: 2px;
    font-size: 0.7em;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(58, 38, 38, 0.31);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.play-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.play-button:focus {
    outline: none;
}

.content-container {
    display: grid;
    grid-template-columns: 1.2fr 0.8fr;
    /* Left column for text, right column for image */
    align-items: start;
    /* Align text and image vertically */
    gap: 20px;
    /* Space between columns */
    width: 100%;
}

.text-content {
    text-align: left;
}

.image-content {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 600px;
    /* Adjust as needed */
}

.image-bg {
    position: absolute;
    top: 8px;
    /* Offset down */
    left: 8px;
    /* Offset right */
    width: 100%;
    height: 100%;
    background-color: #751515;
    border-radius: 15px;
    z-index: 1;
}

.right-image {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    border-radius: 15px;
    z-index: 2;
}


.research-containerTwo {
    margin-top: 4rem;
    background-color: #2a0000;
    width: 100vw;
    /* Ensures full viewport width */
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding: 2rem 0;
    /* Optional: Adds vertical spacing */
    display: flex;
    justify-content: center;
    /* Centers content */
}

.research-container {
    width: 950px;
    /* Fixed width */
}

.inside-container-header {
    font-family: 'OpenSauceSansLight', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif !important;
    font-size: 25px !important;
    color: white !important;
    line-height: 1.5;
    margin-top: 2rem !important;
}

.inside-container-para {
    color: rgba(255, 255, 255, 0.571) !important;

}


.grid-background {
    position: absolute;
    background-color: #510101;
    /* Red background */
    width: 60%;
    height: 120%;
    top: 10%;
    left: 10%;
    z-index: 0;
    /* Push it behind the content */
}

.grid-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 columns */
    gap: 20px;
    background-color: white;
    /* White background */
    padding: 2rem;
    width: 80%;
    position: relative;
    z-index: 1;
    /* Keep it above the red background */
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    /* Optional shadow for depth */
}

.grid-item {
    padding: 20px;
    background-color: #f5f5f5;
    /* Light grey to distinguish items */
    text-align: center;
    border-radius: 10px;
    font-weight: bold;
}

.observation-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 equal columns */
    gap: 20px;
    /* Space between columns */
    width: 100%;
    max-width: 900px;
    /* Adjust as needed */
    margin: auto;
    /* Center the grid */
    padding: 20px;
}

.observation-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 equal columns */
    gap: 30px;
    /* Space between columns */
    width: 100%;
    max-width: 900px;
    /* Adjust as needed */
    margin: auto;
    /* Center the grid */
    padding: 20px;
}

.observation-item {
    position: relative;
    height: 150px;
    /* Fixed height for demonstration */
}

/* White foreground */
.observation-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #eaeaea;
    /* White background */
    color: rgb(84, 83, 83);
    padding: 20px;
    text-align: center;
    font-weight: 200;
    border-radius: 10px;
    box-sizing: border-box;
    z-index: 2;
    /* Above the red background */
}

/* Red background shadow */
.observation-bg {
    position: absolute;
    top: 7px;
    /* Shifted down */
    left: 7px;
    /* Shifted right */
    width: 100%;
    height: 100%;
    background-color: #751515;
    /* Red background */
    border-radius: 10px;
    z-index: 1;
    /* Behind the white content */
}

.project1-section2 {
    padding-top: 40px;
    color: #100f24;
    width: 100%;
    height: 100%;
    /* Adjust the height of the fade effect as needed */
    background: white;

    padding-top: 40px;
    color: #100f24;
    width: 100vw;
    /* Full viewport width */
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background: white;


}





.grid-solution {
    margin-top: 17rem;
    margin-bottom: 30px;
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    background-color: #3D3E78;
    border-radius: 45px;
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
    height: 420px;
}

.designThinking img {
    max-height: 100%;
    max-width: 85%;
    object-fit: contain;
    padding-top: 1rem;
    padding-bottom: 2.5rem;
    /* padding-bottom: 40px; */
}

.phone {
    transform: translateY(-16rem);
}

.phone img {

    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    /* padding-bottom: 3rem; */
}

.phone p {
    padding: 0px 5px;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: #E8EBFA;
    line-height: 1.3rem;
}

.hmw img {
    max-height: 60%;
    max-width: 80%;
    object-fit: contain;
    /* padding-bottom: 1rem; */
}


.process-cards-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-top: 2.5rem;
    margin-bottom: 9rem;
}

.process-card {
    background-color: #FFF5F5;
    border-radius: 23px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.process-title {
    font-family: 'OpenSauceSansLight', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif !important;
    color: #661414;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 0;
}

.process-description {
    font-family: 'OpenSauceSansLight', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif !important;
    color: #303030 !important;
    font-size: 13px;
    line-height: 1.5;
    flex-grow: 1;
    margin-bottom: 20px;
}

.process-duration {
    font-family: 'OpenSauceSansMedium', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
    background-color: #F1BABA;
    color: #661414;
    font-size: 12px;
    padding: 7px 12px;
    border-radius: 30px;
    display: inline-block;
    width: fit-content;
}


/* .ife-observation-layout {
    margin: 60px 0;
} */

.ife-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 4rem;
    align-items: flex-start;
    /* padding-top: 7rem; */
    /* Aligns items to the top */
}

.drawing-grid {
    grid-template-columns: 0.8fr 1.2fr;
    gap: 2rem;
}


.takeaways-grid {
    grid-template-columns: 0.6fr 1.4fr;

}

/* .takeaways-right {
    margin-top: 4rem;
} */

.first-header {
    margin-top: 90px !important;
}

.header-story-delta-long {
    line-height: 1.7;
    margin-right: 5rem;
    margin-top: 0;
    /* Ensures no top margin pushes content down */
    padding-top: 0;
    /* Ensures no top padding pushes content down */
}

.ife-image-area {
    position: relative;

    /* padding-bottom: 40px; */
}


.ife-svg-image {
    /* margin-top: 4.5rem; */
    width: 100%;
    /* Makes image take full width */
    height: auto;
    display: block;
    position: relative;
    z-index: 1;
    border-radius: 30px;
}

.conceptualization-space {
    margin-bottom: 2.5rem;
}

.demo-pic {
    margin-top: 4rem;
    padding: 2rem 0;
    margin-bottom: 0rem;
    padding-bottom: 0;
}

.pic-tag {
    font-family: 'OpenSauceSansLightItalic', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif !important;
    font-size: 14px;
    margin-top: 1.2rem;
    text-align: center;
}

.ife-image-wrapper {
    width: 100%;
    /* Ensures wrapper takes full width */
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    z-index: 1;
}

.ife-description-area {
    margin-top: 30px;
    position: relative;
    z-index: 2;
}

.ife-description-text {
    font-family: 'OpenSauceSans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;

    color: #666;
    font-size: 15px;
    line-height: 1.9;
    margin: 0;
    padding: 0 1rem;

}

.achnowledgment {
    font-family: 'OpenSauceSans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
    padding: 0;
    padding-right: 4rem;
    line-height: 2;
    /* transform: translateY(-1.2rem); */
    font-size: 14px;

}

.full-descrip {
    font-family: 'OpenSauceSans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
    color: #666;
    font-size: 16px;
    line-height: 1.8;
    margin: 0;
    margin-bottom: 2rem;
}

.ife-solution {

    max-width: 100%;
    border-radius: 0px;
    /* padding: 4rem;
    margin: 4rem; */
    /* height: auto;
    display: block;
    margin: 0 auto;
    padding-top: 1rem; */

}

.grid-solution-phone {

    text-align: left;
    /* text-align: center; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* gap: 0px; */
    /* padding: 50px; */
    /* padding-bottom: 2rem; */
    /* Adjust the gap between the columns */
}

.flex-direc {
    margin-top: 1.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.circle-wrapper {
    align-self: flex-start;
    display: flex;
    /* Ensure items are inline */
    align-items: center;
    /* Align items vertically */
    margin-bottom: 0.5rem;
}

.circle-solution-number {
    margin-left: 60px;
    /* margin-top: 0px; */
    /* margin-bottom: 10px; */
    font-weight: 800;
    width: 35px;
    /* Size of the circle */
    height: 35px;
    /* Size of the circle */
    border-radius: 50%;
    /* Make it round */
    background-color: #515390;
    /* Circle color */
    color: #E8EBFA;
    /* Text color */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    /* Adjust the font size of the number as needed */
}

.circle-title {
    margin-left: 0.7rem;
    font-size: 20px;
    /* Size */
    color: #4C4E86;
    /* Color */
    font-weight: 700;
    /* Font weight */
}


.number-on-right {
    margin-right: 20px;
}

.phonemockup img {
    /* transform: scale(1.1); */
    /* max-height: 100%;
    max-width: 100%; */
    max-width: 90%;
    object-fit: contain;
}

.grid-solution-phone p {
    text-align: justify;
    padding-left: 60px;
}

.otherside {
    grid-template-columns: 1fr 1fr;
}

.otherside p {
    padding-right: 20px;
}

.first-frame-delta {
    /* margin-top: 3rem; */
    margin-bottom: 100px;
}

/* .center-title {
    display: flex;
    font-size: 13px;
    justify-content: left;
    padding-bottom: 10px;
    margin-top: 4rem;
} */


.context {
    padding-bottom: 1px;
}

/* Add this to your existing CSS */


.context {
    color: #100f24;
    background-color: white;
    font-size: 10px;
}

.full-text-context {
    transform: translate(-20px, 0px);
}



.full-text {
    font-weight: 400;
    transform: translateY(-35px);

}


.users img {
    max-height: 20%;
    /* Sets the max height to 80% of its container */
    max-width: 20%;
    /* Ensures the image does not exceed the container's width */
    object-fit: contain;
}

.sketchpro {
    max-height: 100%;
    max-width: 90%;
    object-fit: contain;
}

.small-icon-image {


    margin-left: auto;
    margin-right: auto;
    display: block;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transform: translateX(-15px);

}

.third-frame {
    color: #100f24;
    font-weight: 400;
}

.third-frame-main {
    font-weight: 600;
}

.problem-container {
    /* margin: 0;
    padding: 0; */
    /* background-color: #fee5e5;
    border-radius: 35px; */

}

.paragraph-problem {
    font-family: 'OpenSauceSans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
    font-size: 17px;
    line-height: 2;
    padding: 1.5rem;
    color: #6d6d6d !important;
    margin: 0;
    padding: 2.2rem 0rem;

}

.normal-para {
    font-family: 'OpenSauceSans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
    margin-bottom: 2rem;

}

.header-story-delta {
    font-family: 'OpenSauceSansLight', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
    font-size: 30px;
    margin-top: 0rem;
    margin-bottom: 1.5rem;
    color: #393939
}

.Impact-title {
    font-family: 'OpenSauceSansLight', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
    font-size: 1.2rem;
}

.bullet-point {
    margin-top: 1rem;
    display: flex;
    align-items: center;
}




.method {
    font-size: 23px;
    font-weight: 400;
    padding-bottom: 3px;

}



.placeholder-images-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Creates two columns of equal width */
    gap: 40px;
    /* Sets the gap between grid items */
    /* margin-bottom: 20px; */
    /* Keeps your existing bottom margin */
}

.placeholder-image-column {
    display: flex;
    align-items: center;
    /* Centers content vertically */
    justify-content: center;
    /* Centers content horizontally */
    height: 100%;
    /* Ensures the column takes full height of its container */
}

.pieOne img {
    max-height: 80%;
    /* Sets the max height to 80% of its container */
    max-width: 100%;
    /* Ensures the image does not exceed the container's width */
    object-fit: contain;
    /* Maintains aspect ratio and fits the content within the container */
}

.questionOne img {
    max-height: 53%;
    /* Sets the max height to 80% of its container */
    max-width: 100%;
    /* Ensures the image does not exceed the container's width */
    object-fit: contain;
    /* Maintains aspect ratio and fits the content within the container */
}


.questionTwo img {
    /* max-height: 54%; */
    margin-left: 2rem;

}


.placeholder-image {

    margin-left: auto;
    margin-right: auto;
    display: block;
    /* display: flex; */
    align-items: center;
    /* Centers content vertically */
    justify-content: center;
    width: 100%;
    /* Set the width */
    height: 100%;
    /* Set the height */
    border-radius: 10px;
    /* Add rounded corners */
    margin-top: 20px;
    /* Adjust margin as needed */
}

.chart img {
    width: 90%;
    margin-bottom: 3rem;
}

.table img {
    width: 100%;
}



.custom-bullet-point p {
    line-height: 1.5;
    /* Adjust the line height as needed */
}



.custom-bullet-point {

    display: flex;
    align-items: flex-start;
    /* Align items at the start, better for varying text lengths */
    /* margin-bottom: 10px; */
    /* Added to create space between bullet points */
}

.custom-bullet {
    flex-shrink: 0;
    width: 9px;
    height: 9px;
    border-radius: 35%;
    background-color: hwb(239 24% 53%);
    margin-right: 15px;
    margin-top: 1.5rem;
}

.stakeholders .custom-bullet {
    margin-top: 32px;
}

.stakeholders .custom-bullet-point {
    margin-top: 2.5rem;
    margin-bottom: 0px;
}

.stakeholders p {
    line-height: 2.5rem;
    /* Improved line height for readability */
    margin-bottom: 1rem;
    /* Adds space below each paragraph for separation */
}

.rightholder {
    margin-top: 1rem;
}

.rightholder .custom-bullet-point {
    margin-top: 0rem;
    margin-left: 30px;
}




.target {
    position: relative;
    /* Ensure it's positioned */
    overflow: visible;
    /* Make sure overflow is visible */
}

.target::before {
    content: "";
    position: absolute;
    top: 0%;
    /* Adjust these values as needed to control the overflow */
    left: -10%;
    right: -10%;
    bottom: -60%;
    background-image: url('../src/assets/project1/focus.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}







.rightholder p {
    /* padding-top: 1.2rem; */
    margin-top: 35px;
    line-height: 0rem;
}


.key-insight-title {
    color: #3D3E78;
    font-size: 23px;
    font-weight: 600;
    padding-bottom: 1rem;

}


.key-insights {
    border-radius: 35px;
    margin-top: 1rem;
    margin-bottom: 5rem;
    padding-top: 30px;
    padding-bottom: 15px;
    text-align: left;
    padding-left: 50px;
    /* width: 100vw; */
    position: relative;
    height: 100%;
    /* left: calc(-50vw + 50%); */
    background-color: #E8EBFA;
    /* or any other background color you prefer */
}

.bullet-finding {
    transform: translateY(-4px);
    width: 40px;
    height: 40px;
    background-color: #E8EBFA;
    /* or any other background color you prefer */
}

.finding {
    font-weight: 600;
    font-size: 25px;
}

.finding p {
    padding-bottom: 0;
    margin-bottom: 1rem;

}

.interview-container {
    display: grid;
    grid-template-columns: 2.1fr 0.9fr;
    gap: 40px;
}

.interviewPic img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    margin-bottom: 4.5rem;
}



.small-finding {
    transform: translateY(-29px);
    padding-left: 4.3rem;
    font-weight: 400;
    /* padding-bottom: 10px; */
}

.key-findings {
    margin-top: 1.5rem;
    margin-bottom: 5rem;
    background-color: #3D3E78;
    /* or any other background color you prefer */
}

.key-findings p {
    color: #E8EBFA !important;
}

.user-needs {
    padding-left: 3.5rem;
    margin-bottom: 3rem;
}

.chevron {
    transform: translateY(1px);
    color: #5B5FC7;
    font-size: 18px;
    margin-right: 1rem;
}

.user-need {
    color: #5B5FC7;
    font-weight: 700;

}

.findingsmove {
    transform: translateY(7px);
}

.empathy img {
    padding: 20px 0px;
    width: 80%;
}

iframe {
    margin-bottom: 2rem;
}

.matrix {
    text-align: center;
    margin-top: 0;
}

.matrix img {
    width: 90%;
    transform: translateY(-1rem);
    /* margin-bottom: 2rem; */
}

.grid-container-sketches {
    /* padding-top: 1rem; */
    align-items: stretch;
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    gap: 60px;
    /* padding-bottom: 1rem; */
}

.initial {
    text-align: right;
    /* margin-left: 5rem; */
}

.initial img {
    width: 100%;
    border-radius: 1rem;
}


.grid-sketches {

    text-align: center;
    align-items: center;
    /* text-align: center; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    padding-top: 2rem;
    padding-bottom: 3rem;

    /* gap: 0px; */
    /* padding: 50px; */
    /* padding-bottom: 2rem; */
    /* Adjust the gap between the columns */
}

.sketch-first {
    grid-template-columns: 1fr 1fr;
}

.sketches img {
    max-width: 100%;
}

.scenario {
    font-style: italic;
    color: #5B5FC7 !important;
    padding: 1.5rem 6rem;
}

.designsys img {
    max-width: 100%;
}

.prototypes {
    /* padding: 5rem; */
    text-align: center;
    background-color: #e8ebfa4a;
    border-radius: 30px;
    margin-bottom: 4rem;

}

.prototypes img {
    width: 100%;

}

.explanation {
    text-align: center;
    /* background-color: #E8EBFA; */
    border-radius: 40px;
    padding-bottom: 3rem;
}

.title-eval {
    font-weight: 700;
    transform: translateY(-0.4rem);
}

.test-grid {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
    margin-bottom: 3rem;
}

.test-grid img {
    margin-top: 3rem;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    border-width: 1px;
    border-style: solid;
    border-color: #8c8c8c;
    border-radius: 20px;
}

.process-tasks {
    margin-top: 1.5rem;
}

.process-tasks p {
    font-weight: 600;
    padding-left: 1rem;
    color: #4C4E86;
    font-size: 12px;
    line-height: 1.5;
}

.carousel-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
}

.container {
    width: 100%;
    max-width: 900px;
    height: auto;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

input[type=radio] {
    display: none;
}

.cards {
    position: relative;
    width: 100%;
    height: 450px;
    margin-bottom: 0;
}

.card {
    position: absolute;
    width: 500px;
    height: 500px;
    left: 0;
    right: 0;
    margin: auto;
    transition: transform 0.4s ease, opacity 0.4s ease;
    cursor: pointer;
    border-radius: 40px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: #000;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.309);

}

.card video {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.feature-label {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 2;
}

.delta-solution-circle.active {
    background-color: #E79C9C;
    color: rgb(145, 57, 57);
}

#item-1:checked ~ .cards #feature-item-3, 
#item-2:checked ~ .cards #feature-item-1, 
#item-3:checked ~ .cards #feature-item-2 {
    transform: translatex(-40%) scale(0.8);
    opacity: 0.4;
    z-index: 0;
}

#item-1:checked ~ .cards #feature-item-2, 
#item-2:checked ~ .cards #feature-item-3, 
#item-3:checked ~ .cards #feature-item-1 {
    transform: translatex(40%) scale(0.8);
    opacity: 0.4;
    z-index: 0;
}

#item-1:checked ~ .cards #feature-item-1, 
#item-2:checked ~ .cards #feature-item-2, 
#item-3:checked ~ .cards #feature-item-3 {
    transform: translatex(0) scale(1);
    opacity: 1;
    z-index: 1;
}

.player {
    box-shadow: 0px 0px 5px rgba(189, 189, 189, 0.154);
    /* background-color: rgb(225, 175, 209); */
    border-radius: 25px;
    width: 52%;
    /* margin-left: 3rem; */
    position: relative;
    /* top: auto;
    left: auto; */
    /* transform: none; */
    margin-top: 190px;
    z-index: 10;
    padding: 10px;
    padding-bottom: 1.5rem;
    height: 100%;

}

.upper-part {
    position: relative;
    display: flex;
    align-items: flex-start;
    overflow: hidden;

    /* height: 190px; */
    /* width: 100%; */
}

.circle-icon {
    margin-right: 15px;
    margin-top: 4px;
}

.info-area {
    width: 100%;
    position: relative;
    height: 140px;
    overflow: hidden;
}


.feature-info {
    position: absolute;
    top: 0;
    left: 0;
    /* width: 100%;
    height: 150px; */
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, transform 0.4s ease;
}

/* Initial positions */
/* Item 1 is initially visible */
#feature-info-1 {
    transform: translateY(0);
}

/* Items 2 and 3 start below (for going right) */
#feature-info-2, #feature-info-3 {
    transform: translateY(100%);
}

.title-container-caras {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 6px;
    width: 100%;
}

.feature-icon {
    width: 46px;
    height: 46px;
    min-width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.title {
    color: #c46c6c;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    text-align: left;
}

.description {
    font-size: 13.8px;
    line-height: 2.1;
    color: rgb(120, 120, 120);
    /* padding-right: 5px; */
    /* margin-left: 0.5rem;
    margin-right: 3rem; */
    text-align: left;
    font-family: 'OpenSauceSans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;

}

/* Active state for each item */
#item-1:checked ~ .player .info-area #feature-info-1,
#item-2:checked ~ .player .info-area #feature-info-2,
#item-3:checked ~ .player .info-area #feature-info-3 {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    z-index: 2;
}

/* Moving from item 1 to item 2 */
#item-1:checked ~ .player .info-area #feature-info-2 {
    transform: translateY(100%); /* Start below */
}

#item-2:checked ~ .player .info-area #feature-info-1 {
    transform: translateY(-100%); /* Exit above */
}

/* Moving from item 2 to item 3 */
#item-2:checked ~ .player .info-area #feature-info-3 {
    transform: translateY(100%); /* Start below */
}

#item-3:checked ~ .player .info-area #feature-info-2 {
    transform: translateY(-100%); /* Exit above */
}

/* Moving from item 3 to item 1 (loop back) */
#item-3:checked ~ .player .info-area #feature-info-1 {
    transform: translateY(100%); /* Start below */
}

#item-1:checked ~ .player .info-area #feature-info-3 {
    transform: translateY(-100%); /* Exit above */
}

/* When moving right: come in from top, exit from bottom */
/* When moving left: come in from bottom, exit from top */

/* For navigating right */
#item-1:checked ~ .player .info-area #feature-info-2,
#item-2:checked ~ .player .info-area #feature-info-3,
#item-3:checked ~ .player .info-area #feature-info-1 {
    transform: translateY(-100%); /* Next items come from top */
}

/* For navigating left */
#item-2:checked ~ .player .info-area #feature-info-1,
#item-3:checked ~ .player .info-area #feature-info-2,
#item-1:checked ~ .player .info-area #feature-info-3 {
    transform: translateY(100%); /* Previous items come from bottom */
}

/* Hide all inactive content */
#item-1:checked ~ .player .info-area #feature-info-2,
#item-1:checked ~ .player .info-area #feature-info-3,
#item-2:checked ~ .player .info-area #feature-info-1,
#item-2:checked ~ .player .info-area #feature-info-3,
#item-3:checked ~ .player .info-area #feature-info-1,
#item-3:checked ~ .player .info-area #feature-info-2 {
    opacity: 0;
    visibility: hidden;
    z-index: 1;
}